import { ShowConsentBannerUseCase } from './core'
import { VisibilityAdapter } from './adapters'
import { apps, logger } from './singletons'

const hideConsentBannerPaths = [
  '/agb',
  '/datenschutz',
  '/impressum',
  '/shoppages/service/ottopayments/datenschutzinformationen_otto_payments',
]

export const showConsentBannerUseCase = new ShowConsentBannerUseCase(
  new VisibilityAdapter(hideConsentBannerPaths),
  apps,
  logger
)
